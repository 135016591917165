<template>
  <fw-layout back-to="/manage">
    <template #main-content>
      <fw-panel :title="$t('editions')" featured custom-class="bg-white p-2" boxed>
        <template v-if="userPermissions.isGlobalManager" #toolbar>
          <span class="flex flex-1 justify-end">
            <fw-button type="transparent-primary" @click.native="editEdition(null)">{{
              $t('newEdition')
            }}</fw-button></span
          >
        </template>
        <PanelManageEditions ref="panelManageEditions" @view="goToViewEdition($event)"></PanelManageEditions>
      </fw-panel>
    </template>

    <template #modals>
      <fw-modal v-if="isModalEditionActive" :active.sync="isModalEditionActive" :can-cancel="true" @close="closeModal">
        <template #default>
          <ModalCreateEdition :edition="edition" @close="closeModal" @saved="editionSaved()"></ModalCreateEdition>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelManageEditions from '@/components/panels/manage/PanelManageEditions'
import ModalCreateEdition from '@/components/modals/ModalCreateEdition'

export default {
  name: 'ManageEditions',
  components: {
    PanelManageEditions,
    ModalCreateEdition
  },

  data() {
    return {
      isModalActive: false,
      isModalEditionActive: false,
      edition: null // edition being edited
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    userPermissions() {
      return this.$store.getters.userPermissions
    }
  },

  methods: {
    goToViewEdition(edition) {
      this.$router.push({ path: '/manage/edition/' + edition.key + '/dashboard' })
    },
    editEdition(edition) {
      this.edition = edition
      this.isModalEditionActive = true
    },
    editionSaved() {
      this.$refs.panelManageEditions.refresh()
      this.closeModal()
    },
    closeModal() {
      this.isModalEditionActive = false
    }
  }
}
</script>

<i18n>
{
    "pt": {
        "editions": "Edições",
        "newEdition": "Nova edição",
        "configurations": "Configurações"
    },
    "en": {
        "editions": "Editions",
        "newEdition": "New edition",
        "configurations": "Configurations"
    }
}
</i18n>
